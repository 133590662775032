import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterIcon as SolidIcon } from '@heroicons/react/solid';
import { FilterIcon as OutlineIcon } from '@heroicons/react/outline';

import Popup from '@/components/common/Popup';
import {
  CommomTeachingProps,
  TeachingWeekFilterType,
  UpdateFilter,
} from '@/models/TeachingWeek';
import { CourseCategoryEnum, CourseTypeEnum } from '@/models/Course';
import { DaysOfWeekTypeEnum } from '@/models/Klass';
import Text from '@/components/common/dataDisplay/Text';
import useTranslateFilter from '@/utils/translateFilter';
import { FilterType } from '@/models/FilterType';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import Checkbox from '@/components/common/dataInput/Checkbox';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { TeacherSummaryCount } from '../TeacherSummaryCount';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Badge from '@/components/common/dataDisplay/Badge';

type TeachingWeekFiltersProps = Omit<CommomTeachingProps, 'type'> & {
  filters: ListKlassesFilters;
};

export default function TeachingWeekFilters({
  events,
  updateFilters,
  filters,
}: TeachingWeekFiltersProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassesFilters',
  });

  const teachingweekfilters: TeachingWeekFilterType[] = [
    'courseCategory',
    'courseType',
    'dayOfWeek',
  ];

  return (
    <section className="flex flex-wrap gap-5 order-last items-center xl:self-end">
      <ul className="flex flex-wrap lg:flex-nowrap h-fit gap-5 text-base-content text-14">
        {teachingweekfilters.map(filter => {
          const filterEvents = events.filter(({ type }) => type === filter);
          const filterCount = filterEvents.length;
          return (
            <li key={filter}>
              <Popup
                hover
                className={{ reference: 'cursor-pointer', popup: 'z-10' }}
                reference={
                  <Badge
                    count={filterCount}
                    position="-top-1 -right-1"
                    color="info"
                  >
                    <span className="flex gap-1">
                      {t(filter)}
                      <ConditionalRenderer
                        condition={filterCount > 0}
                        fallback={<OutlineIcon className="w-4 text-primary" />}
                      >
                        <SolidIcon className="w-4 text-primary" />
                      </ConditionalRenderer>
                    </span>
                  </Badge>
                }
              >
                <TeachingWeekFilter
                  type={filter}
                  updateFilters={updateFilters}
                  events={filterEvents}
                />
              </Popup>
            </li>
          );
        })}
      </ul>
      <TeacherSummaryCount
        teacherId={filters.teacherId}
        unitId={filters.unitId}
      />
    </section>
  );
}

function TeachingWeekFilter({
  events,
  type,
  updateFilters,
}: CommomTeachingProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'klassesFilters',
  });

  const options = useMemo(() => {
    switch (type) {
      case 'courseCategory':
        return Object.keys(CourseCategoryEnum);
      case 'courseType':
        return Object.keys(CourseTypeEnum);
      case 'dayOfWeek':
        return Object.keys(DaysOfWeekTypeEnum).filter(
          weekday => weekday !== DaysOfWeekTypeEnum.SUNDAY,
        );
      default:
        return [];
    }
  }, [type]);

  return (
    <section className="bg-base-100 p-3.5 shadow-default rounded-xl border border-neutral-content flex flex-col gap-3.5 max-w-44 max-h-32">
      <Text text={t(type)} format="rubik-500" className="text-primary" />
      <ul className={`flex flex-col gap-1.5 pr-1 ${scrollBarClassName}`}>
        {options.map(option => (
          <TeachingWeekFilterItem
            key={option}
            item={option}
            updateFilters={updateFilters}
            checked={events.some(({ event }) => event.value === option)}
            type={type}
          />
        ))}
      </ul>
    </section>
  );
}

type TeachingWeekFilterItemProps = Pick<
  CommomTeachingProps,
  'updateFilters' | 'type'
> & {
  item: string;
  checked: boolean;
};

function TeachingWeekFilterItem({
  type,
  checked,
  item,
  updateFilters,
}: TeachingWeekFilterItemProps) {
  const { translateItem: translate } = useTranslateFilter();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updated: UpdateFilter = {
      type,
      event: { value: event.target.value, checked: event.target.checked },
    };
    updateFilters(updated);
  };

  return (
    <li className="cursor-pointer flex items-center justify-between gap-6 hover:text-primary transition ease-in-out duration-300 text-14">
      <Checkbox
        checked={checked}
        onChange={onChange}
        id={item}
        value={item}
        label={translate({ item, type: type as FilterType })}
        className={{ div: 'flex flex-row-reverse justify-between w-full' }}
      />
    </li>
  );
}
