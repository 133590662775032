import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import DateInput from '@/components/common/dataInput/DateInput';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import PasswordAndConfirmInput from '@/components/common/dataInput/PasswordAndConfirmInput';
import PlacesAutoCompleteInput from '@/components/common/dataInput/PlacesAutoCompleteInput';
import SelectInput from '@/components/common/dataInput/SelectInput';
import Input from '@/components/common/dataInput/TextInput';
import UsernameInput from '@/components/common/dataInput/UsernameInput';
import useAuth from '@/data/hook/useAuth';
import { unitsQueryKeys, usersQueryKeys } from '@/data/services/querykeys';
import { updateUserProfile } from '@/data/services/userProfileServices';
import { createUser, updateUser } from '@/data/services/userServices';
import { ApiError } from '@/models/Errors';
import Unit from '@/models/Unit';
import User, { UserForm, UserStatusEnum, UserTypeEnum } from '@/models/User';
import UserProfile from '@/models/UserProfile';
import { buildChangedObject } from '@/utils/buildChangedObject';
import {
  extractCityAndState,
  returnLocationString,
} from '@/utils/placesLocationUtils';
import alert from '@/utils/UseAlert';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { every, isEmpty, some } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface TeamFormProps {
  user?: User;
  disabled?: boolean;
  className?: string;
  onCancel?: () => void;
  formMode?: 'edit' | 'create';
}

export default function TeamForm(props: TeamFormProps) {
  const { user, disabled, className, onCancel, formMode = 'edit' } = props;

  const { user: userAuth } = useAuth();

  const [hasAlter, setHasAlter] = useState<boolean>(false);

  const userTypes = Object.values(UserTypeEnum)
    .filter(
      usertype =>
        ![UserTypeEnum.STUDENT, UserTypeEnum.PARENT].includes(usertype),
    )
    .filter(
      userType =>
        !(
          userAuth?.userType !== UserTypeEnum.SUPER_ADMIN &&
          userType === UserTypeEnum.SUPER_ADMIN
        ),
    );

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeam.addUser',
  });

  const { t: tRole } = useTranslation('translation', {
    keyPrefix: 'manageTeam',
  });

  const { t: tStatus } = useTranslation('translation', {
    keyPrefix: 'userStatus',
  });

  const isCreateForm = formMode === 'create';

  const isMultipleUnits =
    userAuth?.userType === UserTypeEnum.UNIT_ADMIN &&
    userAuth?.unitsIds?.length > 1;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm<UserForm>({
    defaultValues: {
      ...user,
      ...user?.profile,
      dateOfBirth: user?.profile.dateOfBirth,
      location: returnLocationString(user?.profile.city, user?.profile.state),
      unitsIds: isCreateForm ? userAuth?.unitsIds : user?.unitsIds,
      userType:
        userAuth?.userType === UserTypeEnum.UNIT_ADMIN && isCreateForm
          ? UserTypeEnum.UNIT_ADMIN
          : user?.userType,
    },
  });
  const queryClient = useQueryClient();
  const blockChanges = some(errors, error => !!error?.message);

  const { data: teamUnit } = useQuery({
    ...unitsQueryKeys.get(user?.unitsIds[0] ?? 0),
    enabled: formMode === 'edit' && !!user?.unitsIds[0],
  });

  const status = [
    {
      text: tStatus(UserStatusEnum.REGISTRED),
      value: UserStatusEnum.REGISTRED,
    },
    {
      text: tStatus(UserStatusEnum.INACTIVE),
      value: UserStatusEnum.INACTIVE,
    },
  ];
  const [selectedUnit, setSelectedUnit] = useState<Unit | undefined>(teamUnit);

  const onReset = () => {
    reset({
      ...user?.profile,
      ...user,
      dateOfBirth: user?.profile.dateOfBirth,
      location: returnLocationString(user?.profile.city, user?.profile.state),
    });
    setHasAlter(false);
    onCancel?.();
  };

  const onEdit = async ({
    firstName,
    lastName,
    username,
    userType,
    location,
    dateOfBirth,
    phoneNumber,
    email,
    status,
  }: UserForm) => {
    if (!user) throw new Error('User not found');
    const { city, state } = extractCityAndState(location);

    const parcialProfile = buildChangedObject<UserProfile>(user?.profile, {
      city,
      state,
      phoneNumber,
      dateOfBirth: dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : undefined,
    });

    const parcialUser = buildChangedObject<User>(user, {
      firstName,
      lastName,
      username,
      userType,
      email,
      status,
    });

    !every(parcialUser, isEmpty) && (await updateUser(user.id, parcialUser));

    !every(parcialProfile, isEmpty) &&
      (await updateUserProfile(user?.profile.id, parcialProfile));
  };

  const usersQueryKey = usersQueryKeys.list._def;

  const { mutate: edit, isLoading: isUpdating } = useMutation(onEdit, {
    onSuccess: () => {
      alert.success(t('successEditUser'));
      queryClient.invalidateQueries(usersQueryKey);
      onCancel?.();
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const onCreate = async ({
    location,
    dateOfBirth,
    phoneNumber,
    ...user
  }: UserForm) => {
    const { city, state } = extractCityAndState(location);

    await createUser({
      ...user,
      profile: {
        city,
        state,
        phoneNumber: phoneNumber || undefined,
        dateOfBirth: dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : undefined,
      },
    });
  };

  const { mutate: create, isLoading: isCreating } = useMutation(onCreate, {
    onSuccess: () => {
      alert.success(t('successCreateUser'));
      queryClient.invalidateQueries(usersQueryKey);
      onCancel?.();
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const onSubmit = async (data: UserForm) => {
    isCreateForm ? create(data) : edit(data);
    setHasAlter(false);
  };
  useEffect(() => {
    const subscription = watch(() => setHasAlter(true));
    return () => subscription.unsubscribe();
  }, [watch, setHasAlter]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-4 ${className || ''}`}
    >
      <ConditionalRenderer
        condition={
          isCreateForm && userAuth?.userType === UserTypeEnum.SUPER_ADMIN
        }
      >
        <Controller
          name="unitsIds"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              selectedItem={selectedUnit}
              blockDeselect={isMultipleUnits}
              displayName={unit => unit.name}
              onSelect={unit => {
                const units = [unit.id];
                onChange(units);
                setSelectedUnit(unit);
              }}
              input={{
                label: t('unit'),
                errorLabelText: error?.message,
                labelPosition: 'left',
                disabled,
                testId: 'selectUnit',
                className: { base: 'font-500 text-16' },
              }}
              onDeselect={() => onChange(null)}
            />
          )}
          rules={{
            required: isCreateForm ? t('errorMessageUnit') : undefined,
          }}
        />
      </ConditionalRenderer>
      <Input
        label={t('name')}
        testId="name"
        register={register('firstName', {
          required: t('errorMessageName'),
        })}
        disabled={disabled}
        errorLabelText={errors.firstName?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />
      <Input
        label={t('lastName')}
        testId="lastName"
        register={register('lastName', {
          required: t('errorMessageLastName'),
        })}
        disabled={disabled}
        errorLabelText={errors.lastName?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />

      <UsernameInput
        control={control}
        user={user ?? undefined}
        disabled={disabled}
        labelPosition="left"
        required
        className={{ base: 'font-500 text-16' }}
      />
      <Controller
        control={control}
        name={'dateOfBirth'}
        render={({ field: { onChange, value } }) => (
          <DateInput
            label={t('birth')}
            labelPosition="left"
            testId="birth"
            value={value}
            className={{ base: 'font-500 text-16' }}
            onDateChange={date => {
              onChange(date);
            }}
            disabled={disabled}
          />
        )}
      />
      <Input
        label={t('email')}
        testId="email"
        register={register('email', {
          required: t('errorMessageEmail'),
        })}
        type="email"
        disabled={disabled}
        errorLabelText={errors.email?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />

      <PlacesAutoCompleteInput
        disabled={disabled}
        testId={'city'}
        placeholder={disabled ? '' : t('selectCity')}
        label={t('city')}
        labelPosition="left"
        control={control}
        name="location"
        className={{ base: 'font-500 text-16' }}
      />
      <div
        role="alert"
        className="alert bg-warning-content text-warning font-500"
      >
        <ExclamationIcon className="w-6 h-6" />
        <Text text={tRole('teacherWarning')} />
      </div>
      <SelectInput
        testId="userType"
        label={t('role')}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
        hiddenSelect={disabled}
        defaultValue={user ? tRole(user?.userType) : ''}
        register={register('userType', {
          required: isCreateForm ? t('errorMessageUserType') : undefined,
        })}
        errorLabelText={errors.userType?.message}
        disabled={userAuth?.userType !== UserTypeEnum.SUPER_ADMIN}
      >
        <option disabled value="">
          {t('selectRole')}
        </option>
        {userTypes.map(userType => {
          return (
            <option key={userType} value={userType}>
              {tRole(userType)}
            </option>
          );
        })}
      </SelectInput>

      <Input
        label={t('phoneNumber')}
        register={register('phoneNumber')}
        disabled={disabled}
        errorLabelText={errors.phoneNumber?.message}
        type="phone"
        testId="editTeamPhone"
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />
      <ConditionalRenderer condition={isCreateForm}>
        <PasswordAndConfirmInput
          control={control}
          disabled={disabled}
          labelPosition="left"
          className={{ base: 'font-500 text-16' }}
        />
      </ConditionalRenderer>

      <ConditionalRenderer condition={formMode === 'edit'}>
        <SelectInput
          label={t('status')}
          labelPosition="left"
          className={{ base: 'font-500 text-16' }}
          defaultValue={tStatus(`${user?.status}`)}
          hiddenSelect={disabled}
          register={register('status')}
        >
          {status.map(({ text, value }) => {
            return (
              <option key={value} value={String(value)}>
                {text}
              </option>
            );
          })}
        </SelectInput>
      </ConditionalRenderer>

      <ConditionalRenderer condition={!disabled}>
        <SaveCancelGroup
          loading={isUpdating || isCreating}
          cancel={{
            onClick: onReset,
          }}
          save={{
            disabled: !hasAlter || blockChanges,
          }}
        />
      </ConditionalRenderer>
    </form>
  );
}
