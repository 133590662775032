import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@/components/common/dataDisplay/Text';
import HiddenComponents from '@/components/common/HiddenComponents';
import TeachingWeek from '@/components/staff/TeachingWeek/TeachingWeek';
import AbsenceReports from '@/components/teacher/AbsenceReport/AbsenceReports';
import PendingHomeworks from '@/components/teacher/PendingHomeworks';
import TeacherReports from '@/components/teacher/TeacherReports';
import useConfigContext from '@/data/hook/config';
import useAuth from '@/data/hook/useAuth';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ModalUpdates from '@/components/common/modals/ModalUpdates';

export default function TeacherDashboardView() {
  const { user } = useAuth();

  const { t: tDashboard } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });

  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { hiddenComponents, hideComponent, showComponent } = useConfigContext();

  const classNameCard = { base: 'sm:w-[418px] lg:w-full' };

  const [ref, setRef] = useState<HTMLElement | null>(null);

  if (user)
    return (
      <div className="flex flex-col gap-6 h-full">
        <div className="flex flex-col gap-4">
          <Text
            text={tDashboard('hello') + user?.firstName + ' 👋'}
            className="text-primary"
            format="poppins-600"
            size="text-28"
          />
          <Text
            text={tDashboard('wellcome.teacher')}
            format="rubik-400"
            size="text-16"
            className="break-words"
          />
        </div>
        <div className="flex items-start gap-2.5 md:gap-5 transition-all ease-in-out duration-500 flex-wrap lg:flex-nowrap">
          <TeacherReports className={classNameCard} />
          <PendingHomeworks className={classNameCard} />
          <AbsenceReports className={classNameCard} />
        </div>

        <TeachingWeek
          render={hiddenComponents?.teachingWeek}
          updateRender={hideComponent}
          user={user}
          className={{ section: 'h-[30rem]' }}
          setRef={setRef}
        />
        <HiddenComponents
          render={!!hiddenComponents}
          sections={hiddenComponents}
          updateRender={showComponent}
        />
        <ConditionalRenderer condition={!!ref}>
          <ModalUpdates keyword="teachingWeek" target={ref}>
            <div className="flex grow flex-wrap justify-center items-center">
              <img
                className="flex max-w-40"
                src="/images/weekdayKlassCell.png"
                alt={t('accessibility.weekdayKlassCell')}
              />
              <img
                className="max-w-64"
                src="/images/klassPopup.png"
                alt={t('accessibility.klassPopup')}
              />
            </div>
          </ModalUpdates>
        </ConditionalRenderer>
      </div>
    );

  return null;
}
