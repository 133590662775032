import { Fragment, PropsWithChildren, ReactNode, useState } from 'react';
import { motion } from 'framer-motion';
import { usePopper } from 'react-popper';

import { ClassNameComponent } from '@/utils/ClassNameComponent';
import useToggle from '@/data/hook/useToggle';
import ConditionalRenderer from './ConditionalRenderer';
import { Placement } from '@/models/Placement';
import { dropdown } from '@/utils/animations/commom';

type RefElement = HTMLDivElement | null;

type PopupProps = PropsWithChildren & {
  testId?: string;
  placement?: Placement;
  reference: ReactNode;
  className?: ClassNameComponent;
  hover?: boolean;
  disabled?: boolean;
  offset?: [number, number];
  opened?: boolean;
  onToggle?(): void;
  onOpen?(): void;
  onClose?(): void;
};

export default function Popup({
  testId,
  placement = 'auto',
  reference,
  children: popup,
  className,
  hover,
  disabled,
  offset,
  opened,
  onToggle,
  onOpen,
  onClose,
}: PopupProps) {
  const { isOpen, close, open, toggle } = useToggle();

  const onMouseEnter = hover && !disabled ? onOpen ?? open : undefined;
  const onMouseLeave = hover && !disabled ? onClose ?? close : undefined;

  const [referenceElement, setReferenceElement] = useState<RefElement>(null);
  const [popperElement, setPopperElement] = useState<RefElement>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 20,
        },
      },
    ],
  });

  return (
    <Fragment>
      <div
        data-testid={testId ?? 'popup'}
        style={styles.reference}
        onClick={onToggle ?? toggle}
        ref={setReferenceElement}
        className={className?.reference}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {reference}
      </div>

      <ConditionalRenderer condition={opened ?? isOpen}>
        <ConditionalRenderer condition={!hover}>
          <div
            onClick={onClose ?? close}
            className="fixed inset-0 z-40"
            onMouseEnter={onMouseLeave}
          />
        </ConditionalRenderer>
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={className?.popup}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <motion.div {...dropdown}>{popup}</motion.div>
        </div>
      </ConditionalRenderer>
    </Fragment>
  );
}
