import {
  InformationCircleIcon,
  LockClosedIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link, createSearchParams } from 'react-router-dom';
import useAuth from '@/data/hook/useAuth';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import Klass from '@/models/Klass';
import Tag from '@/components/common/dataDisplay/Tag';
import Text from '@/components/common/dataDisplay/Text';
import { useQuery } from '@tanstack/react-query';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { isStaff, isStudent, isTeacher } from '@/functions/auth';
import User from '@/models/User';
import { CoinIcon, LoadingIcon } from '../../icons';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import IconButton from '@/components/common/buttons/IconButton';
import moment from 'moment';
import TextWithCopy from '@/components/common/dataDisplay/TextWithCopy';
import { motion } from 'framer-motion';
import useAnimationOnView from '@/data/hook/useMotionViewAnimation';
import { buildMotionLIProps } from '@/utils/animations/lessonCardAnimation';
import { useState } from 'react';
import ModalChangePassword from '@/components/common/modals/ModalChangePassword';
import { ModalRewards } from '@/components/common/modals/ModalRewards/ModalRewards';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import InfinityList from '@/components/common/InfinityList';
import {
  enrollmentsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { handleUserFullName } from '@/functions/handleUserFullName';

export interface KlassMembersViewProps {
  klass: Klass;
}

export default function KlassMembersView(props: KlassMembersViewProps) {
  const { klass } = props;
  const { user } = useAuth();

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.dataKlassTable',
  });

  const { data: klassTeacher, isInitialLoading: isLoadingTeacher } = useQuery({
    ...usersQueryKeys.get(klass.teacherId),
    enabled: !!klass.teacherId,
  });

  const status = [
    EnrollmentStatusEnum.ACTIVE,
    EnrollmentStatusEnum.AWAITING_PAYMENT,
    EnrollmentStatusEnum.CONCLUDED,
  ];

  const {
    results: enrollmentList,
    isInitialLoading: isLoadingEnrollments,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteService({
    ...enrollmentsQueryKeys.list({ klassId: klass.id, status })._ctx.infinity,
  });

  const isLoading = isLoadingEnrollments || isLoadingTeacher;

  const url = useUrlForManage({ user: klassTeacher });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-36">
        <LoadingIcon data-testid="loading" className="w-1/6 text-primary/40" />
      </div>
    );
  }

  return (
    <div
      className="py-5 flex flex-col gap-5 ml-8 w-full"
      data-testid="classMembersTab"
    >
      <div className="flex justify-start">
        <Tag
          testId="classViewPill"
          text={t('teacherStudentsPill')}
          icon={<UserGroupIcon className="w-6 h-6" />}
        />
      </div>
      <div className="flex gap-x-5">
        <div className="flex flex-col gap-y-5">
          <Text text={t('teacher')} format="rubik-500" />
          <Text text={t('students')} format="rubik-500" className="self-end" />
        </div>
        <div className="flex flex-col gap-y-5 w-full">
          <ConditionalWrapper
            condition={!isTeacher(user?.userType)}
            wrapper={<Link to={url} />}
          >
            <Text
              testId="teacher"
              text={handleUserFullName(klassTeacher)}
              format="rubik-500"
              className="text-primary"
            />
          </ConditionalWrapper>

          <InfinityList
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onReachEnd={fetchNextPage}
            className="gap-2"
          >
            {enrollmentList.map((enrollment, index) => (
              <EnrollmentItem
                key={enrollment.id}
                index={index}
                enrollment={enrollment}
              />
            ))}
          </InfinityList>
        </div>
      </div>
    </div>
  );
}

type ModalType = 'password' | 'rewards';

type EnrollmentItemProps = {
  enrollment: Enrollment;
  index: number;
};

function EnrollmentItem({ enrollment, index = 0 }: EnrollmentItemProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { student: user, status } = enrollment;

  const email = user.email ?? '';
  const lastLogin = user.lastLogin
    ? moment(user.lastLogin).format('DD MMM YYYY')
    : t('never');

  const url = useUrlForManage({ user });

  const { variants, custom } = buildMotionLIProps(index, 'right', 0.1);

  const motionLiProps = useAnimationOnView({
    variants,
    custom,
  });

  return (
    <motion.li
      {...motionLiProps}
      data-testid="enrollmentItem"
      className="grid grid-cols-2 xl:grid-cols-5 gap-4 w-full items-center px-5 py-4 border border-primary/40 rounded-lg"
    >
      <div className="flex gap-2 xl:col-span-2">
        <Link to={url}>
          <Text
            testId="student"
            text={handleUserFullName(user)}
            format="rubik-500"
            className="shrink-0 flex"
          />
        </Link>
        <EnrollmentTag status={status} />
      </div>
      <div className="order-3 xl:order-none p-0 m-0">
        <TextWithCopy
          className={{ text: 'truncate' }}
          format="rubik-400"
          label={email}
        />
      </div>
      <div className="flex gap-2 items-center justify-self-end order-4 xl:order-none">
        <Text text={lastLogin} className="capitalize" />
        <Tooltip text={t('lastLogin')}>
          <InformationCircleIcon className="text-neutral/50 w-4 h-4" />
        </Tooltip>
      </div>
      <Actions user={user} url={url} />
    </motion.li>
  );
}

function useUrlForManage({ user }: { user?: User }): string {
  const { user: authUser } = useAuth();
  const userType = authUser?.userType;

  let url = '';

  const search = handleUserFullName(user);

  const params = {
    search,
  };

  const nameParams = createSearchParams(params);

  if (isStudent(user?.userType)) {
    if (isStaff(userType)) return `/admin/students/info/?${nameParams}`;
  } else {
    if (!isTeacher(userType)) return `/admin/teachers/info/?${nameParams}`;
  }

  return url;
}

type ActionsTypeProps = {
  user: User;
  url: string;
};

function Actions({ user, url }: ActionsTypeProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const [visibleModal, setVisibleModal] = useState<ModalType>();

  return (
    <div className="flex gap-2 items-center justify-self-end">
      <ModalChangePassword
        user={user}
        visible={visibleModal === 'password'}
        onClickCancel={() => setVisibleModal(undefined)}
      />
      <ModalRewards
        student={user}
        isVisible={visibleModal === 'rewards'}
        onClickConfirm={() => setVisibleModal(undefined)}
        onClickCancel={() => setVisibleModal(undefined)}
      />
      <Tooltip text={t('generalInformation')}>
        <IconButton
          testId="klassInfoButton"
          icon={<InformationCircleIcon className="text-primary w-6 h-6" />}
          href={url}
        />
      </Tooltip>
      <Tooltip text={t('alterPassword')}>
        <IconButton
          icon={<LockClosedIcon className="text-primary w-6 h-6" />}
          onClick={() => setVisibleModal('password')}
        />
      </Tooltip>
      <Tooltip text={t('rewards')}>
        <IconButton
          icon={<CoinIcon className="text-primary w-6 h-6" />}
          onClick={() => setVisibleModal('rewards')}
        />
      </Tooltip>
    </div>
  );
}

function EnrollmentTag({ status }: { status: EnrollmentStatusEnum }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'enrollmentStatus',
  });

  const enrollmentStatus = [
    EnrollmentStatusEnum.AWAITING_PAYMENT,
    EnrollmentStatusEnum.CONCLUDED,
  ];

  const renderEnrollmentTag = enrollmentStatus.includes(status);

  return (
    <ConditionalRenderer condition={renderEnrollmentTag}>
      <Tag
        color="warning"
        className="py-1 px-2 rounded-full text-12 flex text-center"
        text={t(status)}
      />
    </ConditionalRenderer>
  );
}
