import MainButton from '@/components/common/buttons/MainButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Tag from '@/components/common/dataDisplay/Tag';
import DateInput from '@/components/common/dataInput/DateInput';
import PlacesAutoCompleteInput from '@/components/common/dataInput/PlacesAutoCompleteInput';
import TextInput from '@/components/common/dataInput/TextInput';
import { meQueryKeys } from '@/data/services/querykeys';
import { updateUserProfile } from '@/data/services/userProfileServices';
import { updateUser } from '@/data/services/userServices';
import User from '@/models/User';
import UserProfile from '@/models/UserProfile';
import { buildChangedObject } from '@/utils/buildChangedObject';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  extractCityAndState,
  returnLocationString,
} from '@/utils/placesLocationUtils';
import alert from '@/utils/UseAlert';
import {
  PencilIcon,
  SaveIcon,
  UserCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useQueryClient } from '@tanstack/react-query';
import { every, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
export interface PersonalInputs {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  location: any;
  phoneNumber: string;
}

export const PersonalInfo = ({ user }: { user?: User }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountPage',
  });

  const [isRequesting, setIsRequesting] = useState(false);
  const { register, handleSubmit, control, reset, watch } =
    useForm<PersonalInputs>({
      defaultValues: {
        dateOfBirth: user?.profile.dateOfBirth,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.profile.phoneNumber,
        location: returnLocationString(user?.profile.city, user?.profile.state),
      },
    });
  const [isEdit, setIsEdit] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<PersonalInputs> = async ({
    firstName,
    lastName,
    dateOfBirth,
    location,
    phoneNumber,
  }) => {
    const { city, state } = extractCityAndState(location);
    const parsedDate = dateOfBirth
      ? moment(dateOfBirth).format('YYYY-MM-DD')
      : undefined;
    try {
      setIsRequesting(true);

      if (user) {
        const userBody = buildChangedObject<User>(user, {
          firstName,
          lastName,
        });
        const profileBody = buildChangedObject<UserProfile>(user.profile, {
          dateOfBirth: parsedDate,
          city,
          state,
          phoneNumber,
        });
        if (!every(userBody, isEmpty)) {
          await updateUser(user.id, userBody);
        }
        if (!every(profileBody, isEmpty)) {
          await updateUserProfile(user.profile.id, profileBody);
        }
      }
      queryClient.invalidateQueries(meQueryKeys.get._def);
      setHasChanges(false);
      setIsEdit(false);
      alert.success(t('saveSuccess'));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      alert.error(errorMessage);
    } finally {
      setIsRequesting(false);
    }
  };

  useEffect(() => {
    if (user)
      reset({
        dateOfBirth: user?.profile.dateOfBirth,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.profile.phoneNumber,
        location: returnLocationString(user?.profile.city, user?.profile.state),
      });
  }, [reset, t, user]);

  useEffect(() => {
    const subscribe = watch(() => setHasChanges(true));
    return () => subscribe.unsubscribe();
  }, [watch]);

  return (
    <React.Fragment>
      <div className="flex w-full justify-between">
        <Tag
          text={t('personalInfoTitle')}
          icon={<UserCircleIcon className="w-6 h-6" />}
        />
        <MainButton
          text={t('editButton')}
          onClick={() => {
            setIsEdit(true);
          }}
          dataTestId="editAccountPersonal"
          color="custom"
          className="text-primary gap-2 self-end"
          disabled={isEdit}
          icon={<PencilIcon />}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
        <div className="flex flex-col sm:flex-row gap-5">
          <TextInput
            label={t('fieldName')}
            testId="nameField"
            register={register('firstName')}
            disabled={!isEdit}
            className={{ base: 'w-full sm:w-72 md:w-80 lg:w-96' }}
          />
          <TextInput
            label={t('fieldLastname')}
            testId="lastNameField"
            disabled={!isEdit}
            register={register('lastName')}
            className={{ base: 'w-full sm:w-72 md:w-80 lg:w-96' }}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-5">
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { onChange, value } }) => {
              return (
                <DateInput
                  disabledDates={[
                    { from: new Date(), to: new Date(3000, 4, 18) },
                  ]}
                  label={t('fieldDateOfBirth')}
                  disabled={!isEdit}
                  testId="birthField"
                  value={value}
                  onDateChange={value => {
                    onChange(value);
                  }}
                  className={{
                    label: 'w-40',
                    base: 'w-full text-14',
                  }}
                />
              );
            }}
          />
          <TextInput
            label={t('fieldPhoneNumber')}
            testId="phoneField"
            disabled={!isEdit}
            register={register('phoneNumber')}
            type="phone"
            className={{ base: 'w-full' }}
          />
        </div>
        <PlacesAutoCompleteInput
          testId="locationField"
          control={control}
          placeholder={t('selectCity')}
          name="location"
          disabled={!isEdit}
          label={t('fieldCity')}
        />
        <ConditionalRenderer condition={isEdit}>
          <div className="flex gap-2">
            <MainButton
              text={t('cancelButton')}
              disabled={!isEdit}
              icon={<XIcon />}
              type="reset"
              color="neutral"
              onClick={() => {
                reset();
                setHasChanges(false);
                setIsEdit(false);
              }}
              className="mt-3"
            />
            <MainButton
              text={t('saveButton')}
              dataTestId="saveAccountPersonal"
              disabled={!hasChanges || !isEdit}
              icon={<SaveIcon />}
              type="submit"
              loading={isRequesting}
              className="mt-3"
            />
          </div>
        </ConditionalRenderer>
      </form>
    </React.Fragment>
  );
};
