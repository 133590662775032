import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import StatisticsCard from '@/components/common/StatisticsCard';
import IconButton from '@/components/common/buttons/IconButton';
import AvatarName from '@/components/common/dataDisplay/AvatarName';
import Badge from '@/components/common/dataDisplay/Badge';
import Text from '@/components/common/dataDisplay/Text';
import DateRangePicker from '@/components/common/dataInput/DateRangePicker';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import ModalUpdates from '@/components/common/modals/ModalUpdates';
import Notifications from '@/components/notifications/Notifications';
import AbsenceReports from '@/components/teacher/AbsenceReport/AbsenceReports';
import CollapsableCard from '@/components/teacher/CollapsableCard';
import useAuth from '@/data/hook/useAuth';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useListService from '@/data/hook/useListService';
import useStatisticService from '@/data/hook/useStatistics';
import {
  pendingSummariesQueryKeys,
  unitsQueryKeys,
  unitsStatisticsQueryKeys,
} from '@/data/services/querykeys';
import {
  UnitStatisticsFilters,
  updateUnitsStatistics,
} from '@/data/services/statisticsServices';
import { isSuperAdmin, isUnitAdmin } from '@/functions/auth';
import { handleUserFullName } from '@/functions/handleUserFullName';
import {
  TeacherReportStatistics,
  TeacherReportStatisticsCount,
} from '@/models/Statistics';
import { UserTypeEnum } from '@/models/User';
import { ClassNameComponent } from '@/utils/ClassNameComponent';
import { formatDateRange } from '@/utils/formatDateRange';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import {
  UnitCalculatorResult,
  unitStatisticsCalculator,
} from '@/utils/statistics/unitStatisticsCalculators';
import { DocumentReportIcon } from '@heroicons/react/outline';
import { RefreshIcon } from '@heroicons/react/solid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { subDays } from 'date-fns';
import moment from 'moment';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router-dom';

export default function AdminDashboardView() {
  const { t: tDashboard } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });
  const { user } = useAuth();

  const isMultipleUnits =
    user && isUnitAdmin(user.userType) && user.unitsIds.length > 1;

  const [selectedUnit, setSelectedUnit] = useState<number | undefined>(
    isMultipleUnits || isSuperAdmin(user?.userType)
      ? undefined
      : user?.unitsIds[0],
  );

  const dateNow = new Date(Date.now());

  const defaultSelected: DateRange = {
    from: subDays(dateNow, 29),
    to: dateNow,
  };
  const unitIdFilter = getAuthorizedUnits(user);
  const unitId: number[] | undefined = selectedUnit
    ? [selectedUnit]
    : unitIdFilter;

  const [range, setRange] = useState<DateRange>(defaultSelected);

  const { from: dateAfter, to: dateBefore } = formatDateRange(range);

  const filters: UnitStatisticsFilters = {
    dateBefore,
    dateAfter,
    unitId,
  };

  const {
    statistic,
    calculated: unitStatistics,
    isInitialLoading: isLoadingUnitsStatistics,
  } = useStatisticService(
    unitsStatisticsQueryKeys.list(filters),
    unitStatisticsCalculator,
  );
  const { results: teacherReportsCount } = useListService({
    ...pendingSummariesQueryKeys.list({ unitId })._ctx.totalSum,
  });

  const {
    results: teacherReportList,
    isInitialLoading: isLoadingTeacherReports,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteService({
    ...pendingSummariesQueryKeys.list({
      unitId: selectedUnit ? [selectedUnit] : undefined,
    })._ctx.infinity,
  });

  return (
    <div className="flex flex-col gap-4 min-h-full">
      <div className="flex flex-col gap-4">
        <Text
          text={tDashboard('hello') + user?.firstName + ' 👋'}
          className="text-primary"
          format="poppins-600"
          size="text-28"
        />
        <Text
          text={tDashboard('wellcome.admin')}
          format="rubik-400"
          size="text-16"
          className="break-words"
        />
      </div>
      <RenderDashboard
        selectedUnitId={selectedUnit}
        range={range}
        statistics={unitStatistics}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoadingUnitsStatistics={isLoadingUnitsStatistics}
        setRange={setRange}
        updateDate={statistic?.updatedAt}
        setSelectedUnit={setSelectedUnit}
        isLoadingTeacherReports={isLoadingTeacherReports}
        teacherReportsData={{
          teacherReportList,
          teacherReportsCount,
        }}
      />
    </div>
  );
}

type TeacherReportsData = {
  teacherReportList: TeacherReportStatistics[];
  teacherReportsCount: TeacherReportStatisticsCount[];
};

interface RenderDashboardProps {
  selectedUnitId?: number;
  range?: DateRange;
  setRange: Dispatch<SetStateAction<DateRange>>;
  updateDate?: string | Date;
  statistics?: UnitCalculatorResult;
  setSelectedUnit: Dispatch<SetStateAction<number | undefined>>;
  isLoadingTeacherReports: boolean;
  teacherReportsData: TeacherReportsData;
  isLoadingUnitsStatistics: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}

const RenderDashboard = ({
  selectedUnitId,
  range,
  setRange,
  updateDate,
  isLoadingTeacherReports,
  statistics,
  isLoadingUnitsStatistics,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  teacherReportsData: { teacherReportList, teacherReportsCount },
  setSelectedUnit,
}: RenderDashboardProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminDashboard',
  });
  const { user } = useAuth();

  const isMultipleUnits =
    user && isUnitAdmin(user?.userType) && user.unitsIds?.length > 1;

  const { mutate: updateStatistics, isLoading } = useMutation(
    updateUnitsStatistics,
    {
      onSuccess() {
        const statisticQueryKey = unitsStatisticsQueryKeys._def;
        queryClient.invalidateQueries(statisticQueryKey);
      },
    },
  );

  const classNamaCard = { base: 'w-full max-h-full sm:w-[418px]' };

  return (
    <div className="relative flex flex-col gap-2.5 h-full">
      <div
        className="relative flex flex-row flex-wrap lg:flex-nowrap gap-4 justify-between h-full"
        id="newRegistredDashboard"
      >
        <div className="flex flex-col gap-2">
          <DateRangePicker
            className="text-blue-600"
            range={range}
            setRange={setRange}
          />
          <RenderStatisticsCards
            isLoadingUnitsStatistics={isLoadingUnitsStatistics}
            statistics={statistics}
          />
          <div className="flex gap-x-2.5 pb-2.5 items-center text-primary">
            <IconButton
              className="w-6 h-6"
              loading={isLoading}
              onClick={() => updateStatistics({ unitId: selectedUnitId })}
              icon={<RefreshIcon className="w-6 h-6 cursor-pointer" />}
            />

            <Text
              className="text-primary"
              text={`${t('lastUpdated')} ${moment(updateDate).format(
                'ddd, DD/MM/yyyy HH:mm',
              )}`}
            />
          </div>
          <div className="flex gap-3 flex-wrap">
            <ComponentGuard roles={[UserTypeEnum.UNIT_ADMIN]}>
              <AbsenceReports className={classNamaCard} />
            </ComponentGuard>
            <div className="flex flex-col gap-3 w-full">
              <ConditionalRenderer
                condition={isMultipleUnits || isSuperAdmin(user?.userType)}
              >
                <InfiniteSearchInput
                  service={unitsQueryKeys.list}
                  filters={{ idIn: getAuthorizedUnits(user)?.toString() }}
                  displayName={unit => unit.name}
                  input={{
                    testId: 'unitSelect',
                    placeholder: t('allUnits'),
                    className: {
                      base: 'w-full xs:w-96 self-start bg-base-100',
                    },
                  }}
                  onSelect={unit => setSelectedUnit(unit.id)}
                  onDeselect={() => setSelectedUnit(undefined)}
                />
              </ConditionalRenderer>
              <PendingReports
                isLoading={isLoadingTeacherReports}
                teacherReportList={teacherReportList}
                teacherReportCount={teacherReportsCount}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                className={classNamaCard}
              />
            </div>
          </div>
        </div>
        <Notifications className="lg:max-h-[calc(100vh-140px)]" ref={ref} />
      </div>
      <ConditionalRenderer condition={ref.current}>
        <ModalUpdates
          keyword="notifications"
          placement="left-start"
          target={ref.current}
        />
      </ConditionalRenderer>
    </div>
  );
};

const RenderStatisticsCards = ({
  isLoadingUnitsStatistics,
  statistics,
}: {
  isLoadingUnitsStatistics: boolean;
  statistics?: UnitCalculatorResult;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminDashboard',
  });

  return (
    <div className="flex flex-wrap gap-2.5">
      <div className="flex flex-col gap-2.5">
        <StatisticsCard
          title={t('totalStudents')}
          testId="totalStudents"
          info={statistics?.totalStudents || 0}
          isLoading={isLoadingUnitsStatistics}
          className="bg-primary-content border text-primary"
        />
        <StatisticsCard
          title={t('churnCount')}
          testId="churnCount"
          isLoading={isLoadingUnitsStatistics}
          info={(statistics?.churnRate ?? 0) + '%'}
          className="bg-primary-content border text-primary"
        />
      </div>
      <div className="flex flex-col gap-2.5">
        <StatisticsCard
          title={t('totalKlasses')}
          testId="totalKlasses"
          isLoading={isLoadingUnitsStatistics}
          info={statistics?.totalKlasses || 0}
          className="bg-secondary-content border text-secondary"
        />
        <StatisticsCard
          title={t('studentsPerKlass')}
          testId="studentsPerKlass"
          isLoading={isLoadingUnitsStatistics}
          info={statistics?.studentsPerKlassCount || 0}
          className="bg-secondary-content border text-secondary"
        />
      </div>
      <div className="flex flex-col gap-2.5">
        <StatisticsCard
          title={t('totalTeachers')}
          testId="totalTeachers"
          info={statistics?.totalTeachers || 0}
          isLoading={isLoadingUnitsStatistics}
          className="bg-accent-content border text-accent"
        />
        <StatisticsCard
          info={statistics?.studentPerTeacherCount || 0}
          title={t('studentsPerTeacher')}
          testId="studentsPerTeacher"
          isLoading={isLoadingUnitsStatistics}
          className="bg-accent-content border text-accent"
        />
      </div>
      <div className="flex flex-col gap-2.5">
        <StatisticsCard
          title={t('attendanceCount')}
          testId="attendanceCount"
          info={Math.round(statistics?.attendancePercentage ?? 0) + '%'}
          isLoading={isLoadingUnitsStatistics}
          className="bg-primary-content border text-primary"
        />
      </div>
    </div>
  );
};

interface PendingReportsProps {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  teacherReportList: TeacherReportStatistics[];
  teacherReportCount: TeacherReportStatisticsCount[];
  className?: ClassNameComponent;
}

const PendingReports = ({
  isLoading,
  teacherReportList,
  fetchNextPage,
  teacherReportCount,
  hasNextPage,
  isFetchingNextPage,
  className,
}: PendingReportsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'teacherDashboard.teacherReports',
  });

  const reportsCount = teacherReportCount.reduce(
    (acc, report) => acc + (report?.pendingReportsCount ?? 0),
    0,
  );

  return (
    <CollapsableCard
      headerIcon={<DocumentReportIcon className="w-6" />}
      title={t('pendingReports')}
      isLoading={isLoading}
      badgeCount={reportsCount}
      isCollapsable={!!teacherReportList.length}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onReachEnd={fetchNextPage}
      className={className}
    >
      <ConditionalRenderer
        condition={teacherReportList.length}
        fallback={
          <div className="flex justify-center self-center shrink text-center items-center h-32 gap-2 px-5 py-3 flex-col gap-y-2 text-accent font-500">
            <Text className="shrink" text={t('noReportsPhrase')} />
            <Text text="\ (•◡•) /" />
          </div>
        }
      >
        {teacherReportList?.map(item => (
          <li key={item.teacher.id} className="h-fit">
            <Badge count={item.pendingReportsCount} position="-top-1 left-4">
              <AvatarName
                href={`/admin/teachers/?${createSearchParams({
                  search: handleUserFullName(item.teacher),
                })}`}
                user={item.teacher}
              />
            </Badge>
          </li>
        ))}
      </ConditionalRenderer>
    </CollapsableCard>
  );
};
