import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import ConditionalRenderer from '../ConditionalRenderer';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

export type CheckboxProps = {
  value?: string | number | number[];
  label?: ReactNode | string;
  className?: ClassNameComponent;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'className'>;
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (checkboxProps, ref) => {
    const { id, onClick, checked, value, label, className, ...props } =
      checkboxProps;
    const alternativeId = 'checkbox' + value;
    return (
      <div className={twMerge('flex gap-2 items-center', className?.div)}>
        <input
          ref={ref}
          id={id || alternativeId}
          data-testid={id || alternativeId}
          type="checkbox"
          {...props}
          className={twMerge(
            'checkbox-sm checkbox checkbox-primary border rounded-sm w-3.5 h-3.5',
            className?.input,
          )}
          onClick={onClick}
          checked={checked}
          value={value}
        />

        <CheckboxLabel label={label} htmlFor={id || alternativeId} />
      </div>
    );
  },
);
type CheckboxLabelProps = {
  label: ReactNode | string;
  htmlFor: string;
};
const CheckboxLabel = ({ label, htmlFor }: CheckboxLabelProps) => {
  return (
    <ConditionalRenderer condition={label}>
      {typeof label === 'string' ? (
        <label
          className="text-left text-14 font-400 text-base-content cursor-pointer leading-4"
          htmlFor={htmlFor}
        >
          {label}
        </label>
      ) : (
        label
      )}
    </ConditionalRenderer>
  );
};

export default Checkbox;
