import { useTranslation } from 'react-i18next';
import Text from '../dataDisplay/Text';
import { ArrowDownIcon } from '@heroicons/react/outline';
import ConditionalRenderer from '../ConditionalRenderer';

interface SortToggleProps {
  id: string;
  hideLabel?: boolean;
  icon?: {
    ascending: React.ReactNode;
    descending: React.ReactNode;
  };
  text?: {
    ascending: string;
    descending: string;
  };
  isDescending: boolean;
  onToggleSort?(checked: boolean): void;
}

const SortToggle = ({
  isDescending,
  hideLabel = false,
  icon,
  text,
  id,
  onToggleSort,
}: SortToggleProps) => {
  const defaultIconClassName = 'w-3.5';
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.dataKlassTable.scheduledLessonProgressView',
  });
  return (
    <div className="flex items-center gap-2">
      <ConditionalRenderer condition={!hideLabel}>
        <Text text={t('sort')} size="text-14" />
      </ConditionalRenderer>
      <label htmlFor={id}>
        <div className="cursor-pointer text-primary flex gap-2 items-center">
          <Text
            size="text-14"
            text={
              isDescending
                ? text?.descending || t('descending')
                : text?.ascending || t('ascending')
            }
          />
          <input
            type="checkbox"
            className="sr-only"
            id={id}
            checked={isDescending}
            onChange={() => {
              onToggleSort?.(!isDescending);
            }}
          />
          <ConditionalRenderer condition={!!icon}>
            {!isDescending && icon?.ascending}
            {isDescending && icon?.descending}
          </ConditionalRenderer>
          <ConditionalRenderer condition={!icon}>
            <ArrowDownIcon
              className={`${defaultIconClassName} transition-all duration-150 ease-in-out transform ${
                isDescending ? '' : 'rotate-180'
              }`}
            />
          </ConditionalRenderer>
        </div>
      </label>
    </div>
  );
};

export default SortToggle;
