import { twMerge } from 'tailwind-merge';

import useHiddenComponents from '@/data/hook/useHiddenComponent';
import User from '@/models/User';
import HideContainer, {
  HideContainerProps,
} from '@/components/common/HideContainer';
import TeachingWeekHeader from './TeachingWeekHeader';
import TeachingWeekdays from './TeachingWeekdays';
import { useTeachingWeek } from '@/data/hook/useTeachingWeek';

export type TeachingWeekProps = Pick<
  HideContainerProps,
  'updateRender' | 'render' | 'className'
> & {
  user: User;
  setRef?: (ref: HTMLElement) => void;
};

export default function TeachingWeek(props: TeachingWeekProps) {
  const { user, className } = props;

  const { components } = useHiddenComponents();

  const component = components['teachingWeek'];

  const { updateFilter, updateFilters, events, filters } = useTeachingWeek({
    user,
  });

  return (
    <HideContainer
      {...props}
      icon={component.icon}
      title={component.title}
      type={component.id}
      header={
        <TeachingWeekHeader
          user={user}
          updateFilters={updateFilters}
          events={events}
          updateFilter={updateFilter}
          filters={filters}
        />
      }
      className={{
        header: twMerge(
          'gap-5 p-0 items-start flex-col lg:flex-row',
          className?.header,
        ),
        section: twMerge('flex flex-col gap-3.5 p-4', className?.section),
      }}
    >
      <TeachingWeekdays filters={filters} />
    </HideContainer>
  );
}
