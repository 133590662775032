import { AlertTypeEnum } from '@/models/Alert';
import { CalendarIcon, ClockIcon } from '@heroicons/react/outline';
import { ElementType } from 'react';
import Tag from '../common/dataDisplay/Tag';
import i18n from '@/i18n';

type NotificationTagProps = {
  type: AlertTypeEnum;
};

export const tagsProps: Record<
  AlertTypeEnum,
  {
    className: {
      bg: string;
      text: string;
    };
    text: string;
    icon: ElementType;
  }
> = {
  [AlertTypeEnum.ABSENCE]: {
    className: { bg: 'bg-warning', text: 'text-warning' },
    text: i18n.t(`alerts.types.${AlertTypeEnum.ABSENCE}`),
    icon: CalendarIcon,
  },
  [AlertTypeEnum.LATENESS]: {
    className: { bg: 'bg-info', text: 'text-info' },
    text: i18n.t(`alerts.types.${AlertTypeEnum.LATENESS}`),
    icon: ClockIcon,
  },
};

const NotificationTag = ({ type }: NotificationTagProps) => {
  const { text, className, icon: Icon } = tagsProps[type];

  return (
    <Tag
      icon={<Icon className="w-3.5 h-3.5" strokeWidth="3" />}
      text={text}
      testId="notificationTag"
      color="custom"
      className={`px-1.5 py-1 !text-14 gap-1 text-base-100 ${className.bg}`}
    />
  );
};

export default NotificationTag;
