import { motion } from 'framer-motion';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { FilterIcon } from '@heroicons/react/solid';
import { Tooltip } from '../dataDisplay/Tooltip';
import { KlassStatusEnum } from '@/models/Klass';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import MainButton from '../buttons/MainButton';
import useFilterParams from '@/utils/UseFilterParams';
import ConditionalRenderer from '../ConditionalRenderer';
import Unit from '@/models/Unit';
import { UserStatusEnum, UserTypeEnum } from '@/models/User';
import useAuth from '@/data/hook/useAuth';
import { isSuperAdmin, isTeacher, isUnitAdmin } from '@/functions/auth';
import { CourseTypeEnum } from '@/models/Course';
import { ListCourseBaseFilters } from '@/data/services/courseServices';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { ListUserProfileFilters } from '@/data/services/userProfileServices';
import useTranslateFilter from '@/utils/translateFilter';
import { LoadingIcon } from '../../icons';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import InfinityList from '../InfinityList';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { unitsQueryKeys } from '@/data/services/querykeys';
import { ListUsersFilters } from '@/data/services/userServices';
import { FilterType } from '@/models/FilterType';

type DropdownProps = {
  filter: string[];
  type: FilterType;
  position?: { x: number; y: number };
};

export default function Dropdown(props: DropdownProps) {
  const { user } = useAuth();

  const isMultipleUnits = user && user.unitsIds?.length > 1;

  const authorizedUser =
    isSuperAdmin(user?.userType) ||
    ((isUnitAdmin(user?.userType) || isTeacher(user?.userType)) &&
      isMultipleUnits);

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { type, position, filter } = props;

  const [isOpen, setIsOpen] = useState(false);

  const scale = isOpen ? 1 : 0.9;

  const opacity = isOpen ? 1 : 0;

  const x = position?.x ?? -250;
  const y = position?.y ?? 20;

  const zIndex = isOpen ? 'z-10' : '-z-10';

  const { setFilterParams, deleteFilterParams } = useFilterParams({});

  const [dropdownFilter, setDropdownFilter] = useState<string[]>(filter);

  useEffect(() => {
    setDropdownFilter(filter);
  }, [filter]);

  const [events, setEvents] = useState<React.ChangeEvent<HTMLInputElement>[]>(
    [],
  );

  function setFilter(event: React.ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    const { value, checked } = target;

    if (checked) {
      setDropdownFilter(prev => [...prev, value]);
      setEvents(prev => [...prev, event]);
    } else {
      setDropdownFilter(prev => [...prev.filter(element => element !== value)]);
      setEvents(prev => [
        ...prev.filter(element => element.target.id !== value),
      ]);
    }
  }

  function clearFilter() {
    setDropdownFilter([]);
    events.forEach(event => {
      event.target.checked = false;
    });
  }

  const pageNumber = 1;

  function mountFilter() {
    switch (type) {
      case 'enrollmentStatus':
        if (dropdownFilter?.length)
          setFilterParams<ListKlassesFilters>({
            enrollmentStatus: dropdownFilter,
            pageNumber,
          });
        else deleteFilterParams<ListKlassesFilters>('status');
        break;
      case 'klassStatus':
      case 'userStatus':
      case 'staffStatus':
        if (dropdownFilter?.length)
          setFilterParams<ListKlassesFilters>({
            status: dropdownFilter,
            pageNumber,
          });
        else deleteFilterParams<ListKlassesFilters>('status');
        break;
      case 'unitId':
        if (dropdownFilter?.length)
          setFilterParams<ListKlassesFilters>({
            unitId: dropdownFilter.map(Number),
            pageNumber,
          });
        else deleteFilterParams<ListKlassesFilters>('unitId');
        break;
      case 'unit':
        if (dropdownFilter?.length)
          setFilterParams<ListUsersFilters>({
            unit: dropdownFilter.map(Number),
            pageNumber,
          });
        else deleteFilterParams<ListUserProfileFilters>('unit');
        break;
      case 'userType':
        if (dropdownFilter?.length)
          setFilterParams<ListUserProfileFilters>({
            userType: dropdownFilter,
            pageNumber,
          });
        else deleteFilterParams<ListUserProfileFilters>('userType');
        break;
      case 'courseType':
        if (dropdownFilter?.length)
          setFilterParams<ListCourseBaseFilters>({
            courseType: dropdownFilter as CourseTypeEnum[],
            pageNumber,
          });
        else deleteFilterParams<ListCourseBaseFilters>('courseType');
        break;
    }

    setIsOpen(false);
  }

  const {
    results: units,
    isInitialLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteService({
    keepPreviousData: true,
    enabled: (type === 'unit' || type === 'unitId') && isOpen && authorizedUser,
    staleTime: REQUEST_STALE_TIME_IN_MS,

    ...unitsQueryKeys.list({
      idIn:
        isTeacher(user?.userType) || isUnitAdmin(user?.userType)
          ? user?.unitsIds.toString()
          : undefined,
    })._ctx.infinity,
  });

  const isLoading = isInitialLoading;

  const list = useMemo(() => {
    const unitsList =
      type === 'unit' || type === 'unitId'
        ? units?.map(unit => unit.name)
        : undefined;

    const klassStatus =
      type === 'klassStatus'
        ? Object.keys(KlassStatusEnum).map(value => value)
        : undefined;

    const userStatus =
      type === 'userStatus'
        ? Object.keys(UserStatusEnum).map(value => value)
        : undefined;

    const enrollmentStatus =
      type === 'enrollmentStatus'
        ? Object.keys(EnrollmentStatusEnum).map(value => value)
        : undefined;

    const userTypeList =
      type === 'userType'
        ? Object.values(UserTypeEnum)
            .map(value => value)
            .filter(
              value =>
                value !== UserTypeEnum.PARENT && value !== UserTypeEnum.STUDENT,
            )
        : undefined;

    const userTypeUnitList = isUnitAdmin(user?.userType)
      ? userTypeList?.filter(value => value !== UserTypeEnum.SUPER_ADMIN)
      : userTypeList;

    const courseTypeList =
      type === 'courseType'
        ? Object.keys(CourseTypeEnum).map(value => value)
        : undefined;

    const staffStatus =
      type === 'staffStatus'
        ? Object.values(UserStatusEnum)
            .filter(
              value =>
                ![
                  UserStatusEnum.WAITING_ENROLLMENT,
                  UserStatusEnum.ACTIVE_ENROLLMENT,
                ].includes(value),
            )
        : undefined;

    switch (type) {
      case 'enrollmentStatus':
        return enrollmentStatus;
      case 'klassStatus':
        return klassStatus;
      case 'userStatus':
        return userStatus;
      case 'unit':
      case 'unitId':
        return unitsList;
      case 'userType':
        return userTypeUnitList;
      case 'courseType':
        return courseTypeList;
      case 'staffStatus':
        return staffStatus;
    }
  }, [type, units, user?.userType]);

  return (
    <nav className="relative flex">
      <motion.button
        data-testid={type + 'DropdownButton'}
        onClick={() => setIsOpen(prev => !prev)}
        whileTap={{ scale: 0.9 }}
      >
        <Tooltip text={undefined}>
          <FilterIcon className="w-4 text-base-100" />
        </Tooltip>
      </motion.button>
      <ConditionalRenderer condition={isOpen}>
        <div
          className={`fixed w-screen h-screen inset-0 bg-black/50 ${zIndex}`}
          onClick={() => setIsOpen(false)}
        />
      </ConditionalRenderer>

      <motion.div
        data-testid="dropdownUnorderedList"
        className={`flex flex-col gap-2 items-start w-64 h-52 bg-base-100 rounded-lg shadow-default absolute p-4 justify-between ${zIndex}`}
        animate={{ scale, opacity, x, y }}
        transition={{ type: 'spring', duration: 0.25 }}
      >
        <ConditionalRenderer
          condition={!isLoading}
          fallback={
            <div className="flex w-full h-full justify-center items-center">
              <LoadingIcon className="w-20 text-primary-content" />
            </div>
          }
        >
          <InfinityList
            onReachEnd={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            scroll
          >
            <DropdownList
              type={type}
              list={list}
              setFilter={setFilter}
              filter={dropdownFilter}
              events={events}
              units={units}
            />
          </InfinityList>
        </ConditionalRenderer>
        <div className="flex gap-4 w-full justify-between items-center">
          <MainButton
            text={tCommon('clear')}
            onClick={clearFilter}
            color="custom"
            className="text-neutral/50"
            dataTestId={type + 'ClearFilterButton'}
          />
          <MainButton
            text="OK"
            onClick={mountFilter}
            dataTestId={type + 'FilterButton'}
          />
        </div>
      </motion.div>
    </nav>
  );
}

type DropdownListProps = DropdownProps & {
  list?: string[];
  enrollmentStatus?: EnrollmentStatusEnum[];
  filter: string[];
  setFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  events: React.ChangeEvent<HTMLInputElement>[];
  units?: Unit[];
};

function DropdownList(props: DropdownListProps) {
  const { list, type, filter, setFilter, units } = props;

  if (list) {
    return (
      <Fragment>
        {list.map(item => {
          const findUnit = units?.find(unit => unit.name === item);

          if (findUnit) {
            const checked = filter.some(value => {
              return value === findUnit.id.toString();
            });
            return (
              <DropdownItem
                id={findUnit.id.toString()}
                key={item}
                item={item}
                type={type}
                setFilter={setFilter}
                checked={checked ?? false}
              />
            );
          } else {
            const checked = filter.some(value => {
              return value === item;
            });

            return (
              <DropdownItem
                key={item}
                item={item}
                type={type}
                setFilter={setFilter}
                checked={checked ?? false}
              />
            );
          }
        })}
      </Fragment>
    );
  }

  return null;
}

type DropdownItemProps = {
  id?: string;
  item: string;
  checked: boolean;
  type: FilterType;
  setFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function DropdownItem({
  id,
  item,
  type,
  checked,
  setFilter,
}: DropdownItemProps) {
  const onClickChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event);
  };

  const ref = useRef<HTMLInputElement>(null);

  const { translateItem } = useTranslateFilter();

  return (
    <li className="flex gap-2.5 justify-start label cursor-pointer">
      <input
        ref={ref}
        data-testid={type}
        id={item}
        type="checkbox"
        className="checkbox-sm checkbox checkbox-primary border rounded-sm"
        value={id ?? item}
        onChange={event => onClickChecked(event)}
        checked={checked}
      />

      <span
        className="text-left text-14 font-400 text-base-content"
        onClick={() => ref.current?.click()}
      >
        {id ? item : translateItem({ type, item: id ?? item })}
      </span>
    </li>
  );
}
