import { useCallback, useState } from 'react';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { DaysOfWeekTypeEnum } from '@/models/Klass';
import { scrollXClassName } from '@/utils/scrollBarClassName';
import TeachingWeekday from './TeachingWeekday';
import useTranslateFilter from '@/utils/translateFilter';
import Tag from '@/components/common/dataDisplay/Tag';
import Text from '@/components/common/dataDisplay/Text';
import InfinityList from '@/components/common/InfinityList';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { LoadingIcon } from '@/components/icons';
import User from '@/models/User';
import StudentModals, {
  StudentModalState,
  StudentModalsType,
} from './StudentModals';
import { useTranslation } from 'react-i18next';

type TeachingWeekdaysProps = {
  filters: ListKlassesFilters;
};

export type KlassDayPeriod = `${DaysOfWeekTypeEnum}_${Period}`;

export type KlassDayPeriodState = {
  count?: number;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  fetchNextPage(): void;
  hasNextPage?: boolean;
  isFetching: boolean;
};

export type Period = 'AM' | 'PM' | 'ALL_DAY';
export default function TeachingWeekdays({ filters }: TeachingWeekdaysProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const klassDays: KlassDaysPeriods = {
    [DaysOfWeekTypeEnum.MONDAY]: 'ALL_DAY',
    [DaysOfWeekTypeEnum.TUESDAY]: 'ALL_DAY',
    [DaysOfWeekTypeEnum.WEDNESDAY]: 'ALL_DAY',
    [DaysOfWeekTypeEnum.THURSDAY]: 'ALL_DAY',
    [DaysOfWeekTypeEnum.FRIDAY]: 'ALL_DAY',
    [DaysOfWeekTypeEnum.SATURDAY]: ['AM', 'PM'],
  };

  type KlassDayPeriodStateMap = {
    [key in KlassDayPeriod]?: KlassDayPeriodState;
  };
  const [klassDayPeriodState, setKlassDayPeriodState] =
    useState<KlassDayPeriodStateMap>({});

  type KlassDaysPeriods = {
    [key in DaysOfWeekTypeEnum]?: Period[] | Period;
  };

  const weekdays = Object.keys(klassDays).flatMap(key => {
    const periods = klassDays[key as DaysOfWeekTypeEnum];
    if (periods && Array.isArray(periods)) {
      return periods.map(period => ({
        day: key as DaysOfWeekTypeEnum,
        period,
      }));
    }
    return [{ day: key as DaysOfWeekTypeEnum, period: 'ALL_DAY' as Period }];
  });
  const klassPeriodStatesValues = Object.values(klassDayPeriodState);
  const hasPeriodStateValues =
    !!klassPeriodStatesValues.length &&
    klassPeriodStatesValues.length === weekdays.length;
  const hasNextPage =
    hasPeriodStateValues &&
    klassPeriodStatesValues.some(({ hasNextPage }) => hasNextPage);

  const isRequestingForAllDays = klassPeriodStatesValues.every(
    ({ isLoading }) => isLoading || isLoading === undefined,
  );
  const isAllDaysEmpty =
    hasPeriodStateValues &&
    klassPeriodStatesValues.every(({ count }) => {
      return count !== undefined && count === 0;
    });

  const isFetchingNextPage = klassPeriodStatesValues.some(
    ({ isFetchingNextPage }) => isFetchingNextPage,
  );

  const updateKlassDayPeriodInfo = useCallback(
    (klassDayPeriod: KlassDayPeriod, payload: KlassDayPeriodState) => {
      setKlassDayPeriodState(prev => ({
        ...prev,
        [klassDayPeriod]: {
          ...payload,
        },
      }));
    },
    [],
  );

  async function fetchNextPages() {
    const isAllPeriodsResolved = klassPeriodStatesValues.every(
      ({ isFetching }) => !isFetching,
    );
    if (!isAllPeriodsResolved) return;

    const nextPageRequests = klassPeriodStatesValues.map(({ fetchNextPage }) =>
      fetchNextPage(),
    );
    await Promise.all(nextPageRequests);
  }

  const [modalState, setModalState] = useState<StudentModalState>();

  const onChangeModal = (modal?: StudentModalsType, student?: User) => {
    setModalState({ modal, student });
  };

  return (
    <section
      className={`flex flex-col gap-1 w-full justify-between grow ${scrollXClassName}`}
    >
      <StudentModals onChangeModal={onChangeModal} modalState={modalState} />
      <ul className="grid grid-cols-7 gap-2 justify-between min-w-[1024px]">
        {weekdays.map(({ day, period }) => (
          <WeekdayTag key={`${day}-${period}`} weekday={day} period={period} />
        ))}
      </ul>
      <InfinityList
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onReachEnd={fetchNextPages}
        className="scrollbar-none min-w-[1024px] grid grid-cols-7 gap-2 grow pb-10"
        scroll
      >
        <ConditionalRenderer condition={isRequestingForAllDays}>
          <div className="flex w-full h-full justify-center col-span-full">
            <LoadingIcon className="text-primary/40 w-10" />
          </div>
        </ConditionalRenderer>
        {weekdays.map(({ day, period }) => (
          <TeachingWeekday
            updateKlassDayPeriodInfo={updateKlassDayPeriodInfo}
            key={`${day}-${period}`}
            filters={filters}
            weekday={day}
            period={period}
            onChangeModal={onChangeModal}
          />
        ))}

        <ConditionalRenderer condition={isAllDaysEmpty}>
          <div className="flex w-full h-full justify-center pt-4 col-span-full">
            <Text
              text={`${t('noResults')} ¯\\_(ツ)_/¯`}
              className="overflow-hidden self-center text-primary"
            />
          </div>
        </ConditionalRenderer>
      </InfinityList>
    </section>
  );
}

type WeekdayTagProps = {
  weekday: DaysOfWeekTypeEnum;
  period: Period;
};

function WeekdayTag({ weekday: item, period }: WeekdayTagProps) {
  const { translateItem: translate } = useTranslateFilter();

  const weekday = translate({
    type: 'dayOfWeek',
    item,
  });

  const formatedWeekDay = weekday
    .slice(0, 3)
    .concat(period !== 'ALL_DAY' ? `-${period}` : '');

  return (
    <li className="flex justify-center w-full">
      <Tag
        color="custom"
        className="px-3 py-1.5 bg-primary text-12 xl:text-16 text-base-100"
      >
        <Text text={formatedWeekDay} className="text-nowrap" />
      </Tag>
    </li>
  );
}
