import updatesRepository from '@/functions/updatesRepository';
import { XIcon } from '@heroicons/react/outline';
import { Placement } from '@popperjs/core';
import moment from 'moment';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { twMerge } from 'tailwind-merge';
import { UpdateKeywords } from '@/models/Updates';
import ConditionalRenderer from '../ConditionalRenderer';
import IconButton from '../buttons/IconButton';
import RoundedButton from '../buttons/RoundedButton';
import Text from '../dataDisplay/Text';
import ConditionalWrapper from '../ConditionalWrapper';
import { ClassNameComponent } from '@/utils/ClassNameComponent';

type ModalUpdatedProps = Partial<PropsWithChildren> & {
  keyword: UpdateKeywords;
  target?: HTMLElement | null;
  offset?: [number, number];
  placement?: Placement;
  className?: ClassNameComponent;
};

const ModalUpdates = ({
  keyword,
  target,
  offset,
  placement = 'auto',
  ...props
}: ModalUpdatedProps) => {
  const [read, setRead] = useState(false);
  const { children } = props;

  const [modalref, setModalref] = useState<HTMLDivElement | null>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'updates' });

  const { styles, attributes } = usePopper(target, modalref, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  });

  const hasRead = useMemo(
    () => updatesRepository.get(keyword)?.read,
    [keyword],
  );

  const onClose = () => {
    updatesRepository.update(keyword, {
      read: true,
      readAt: moment().toDate(),
    });
    setRead(true);
  };

  useEffect(() => {
    const updated = updatesRepository.get(keyword);
    setRead(!!updated?.read);
  }, [keyword]);

  return (
    <ConditionalRenderer condition={!hasRead}>
      <ConditionalWrapper
        condition={!target}
        wrapper={
          <div
            className={twMerge(
              'modal flex items-center justify-center bg-transparent',
              !read ? 'modal-open' : '',
            )}
          />
        }
      >
        <div
          ref={ref => setModalref(ref)}
          style={styles.popper}
          {...attributes.popper}
          className={twMerge(
            'modal-box items-center justify-center z-10 border-[1px] border-neutral-content',
            !read ? 'flex' : 'hidden',
            !target ? 'relative' : '',
          )}
        >
          <IconButton
            icon={<XIcon className=" text-base-contentw-6 h-6" />}
            className="absolute top-4 right-4"
            onClick={onClose}
          />
          <div className="flex w-full flex-col items-center gap-4">
            <Text
              text={t(`${keyword}.title`)}
              format="rubik-500"
              className="text-primary"
              size="text-20"
            />
            <Text text={t(`${keyword}.description`)} size="text-16" />
            <div className="pointer-events-none">{children}</div>
            <RoundedButton
              color="neutral"
              className="w-full"
              onClick={onClose}
              text={t('gotIt')}
            />
          </div>
        </div>
      </ConditionalWrapper>
    </ConditionalRenderer>
  );
};

export default ModalUpdates;
