import CheckGroup, {
  CheckGroupContainer,
  CheckOption,
} from '../dataInput/CheckGroup';
import Text from '../dataDisplay/Text';
import { CourseTypeEnum } from '@/models/Course';
import { useEffect } from 'react';
import ConditionalRenderer from '../ConditionalRenderer';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FeedbackToggleValue } from '@/pages/feedback/TeacherFeedbackPage';
import { LoadingIcon } from '@/components/icons';
import useAuth from '@/data/hook/useAuth';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import InfinityList from '../InfinityList';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { isSuperAdmin } from '@/functions/auth';
import {
  courseBasesQueryKeys,
  unitsQueryKeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';

export type FeedbackFiltersOptions = {
  courseType?: CourseTypeEnum[];
  courseId?: number[];
  unitId?: number[];
};

export default function FeedbackFilters({
  setFilters,
  filters,
  type,
}: {
  setFilters: (filters: FeedbackFiltersOptions) => void;
  filters?: FeedbackFiltersOptions;
  type: FeedbackToggleValue;
}) {
  const { user } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage.filter',
  });

  const { t: tCourseTypes } = useTranslation('translation', {
    keyPrefix: 'courseTypes',
  });

  const isMultipleUnits = !!user?.unitsIds.length && user.unitsIds.length > 1;

  const courseCheckOptions: CheckOption[] = Object.values(CourseTypeEnum).map(
    type => ({ label: tCourseTypes(type), value: type }),
  );

  const { register, watch } = useForm<FeedbackFiltersOptions>({
    defaultValues: {
      courseId: filters?.courseId,
      courseType: filters?.courseType,
      unitId: filters?.unitId,
    },
  });

  const { results: coursesList, isInitialLoading: isLoadingCourses } =
    useInfiniteService({
      ...courseBasesQueryKeys.list({
        coursePathStatus: [VersioningStatusEnum.PUBLISHED],
        courseType: filters?.courseType,
      }),
      enabled: !!filters?.courseType?.length && type !== 'course',
    });

  const {
    results: unitList,
    isInitialLoading: isLoadingUnits,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteService({
    enabled: isSuperAdmin(user?.userType) || isMultipleUnits,
    ...unitsQueryKeys.list({ idIn: getAuthorizedUnits(user)?.toString() })._ctx
      .infinity,
  });

  const moduleCheckOptions: CheckOption[] = coursesList?.map(course => {
    return { label: course.abbreviation, value: course.id } as CheckOption;
  });

  useEffect(() => {
    const w = watch(values => {
      const validCourseIdList = coursesList
        .filter(
          course =>
            course.courseType &&
            values?.courseType?.includes(course.courseType),
        )
        .map(course => course.id);

      const validCourseIdFilter = values.courseId?.length
        ? values?.courseId?.filter(
            id => id && validCourseIdList.includes(Number(id)),
          )
        : [];
      setFilters(
        Object.assign({}, filters, {
          ...values,
          courseId: validCourseIdFilter?.length
            ? validCourseIdFilter
            : undefined,
        }),
      );
    });
    return () => w.unsubscribe();
  }, [watch, setFilters, filters, coursesList]);

  return (
    <div className="border-r-[0.05rem] border-primary/40 flex flex-col gap-2">
      <Text format="rubik-500" text="Filtros" />
      <CheckGroupContainer
        title={t('course')}
        badgeCount={filters?.courseType?.length}
        testId="courseCheckGroup"
      >
        <CheckGroup {...register('courseType')} options={courseCheckOptions} />
      </CheckGroupContainer>

      <ConditionalRenderer condition={type !== 'course'}>
        <CheckGroupContainer
          title={t('module')}
          badgeCount={filters?.courseId?.length}
          testId="moduleCheckGroup"
        >
          <ConditionalRenderer
            condition={!isLoadingCourses}
            fallback={
              <div className="w-full h-12 items-center justify-center flex">
                <LoadingIcon className="h-7 w-7 text-primary" />
              </div>
            }
          >
            <ConditionalRenderer
              condition={!!filters?.courseType?.length}
              fallback={<Text className="text-14" text={t('selectCourse')} />}
            >
              <CheckGroup
                {...register('courseId')}
                options={moduleCheckOptions}
              />
            </ConditionalRenderer>
          </ConditionalRenderer>
        </CheckGroupContainer>
      </ConditionalRenderer>
      <ConditionalRenderer
        condition={isSuperAdmin(user?.userType) || isMultipleUnits}
      >
        <CheckGroupContainer
          title={t('units')}
          badgeCount={filters?.unitId?.length}
          testId="unitCheckGroup"
        >
          <ConditionalRenderer
            condition={!isLoadingUnits}
            fallback={
              <div className="w-full h-12 items-center justify-center flex">
                <LoadingIcon className="h-7 w-7 text-primary" />
              </div>
            }
          >
            <InfinityList
              className="relative max-h-[200px] pr-5 gap-4 overflow-x-hidden"
              scroll
              onReachEnd={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            >
              <CheckGroup
                options={unitList.map(unit => ({
                  label: unit.name,
                  value: unit.id,
                }))}
                {...register('unitId')}
              />
            </InfinityList>
          </ConditionalRenderer>
        </CheckGroupContainer>
      </ConditionalRenderer>
    </div>
  );
}
