import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { Collapse } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../data/hook/useAuth';
import { isTeacher } from '../../../../functions/auth';
import MainButton from '../../../common/buttons/MainButton';
import PillComponent from '../../../common/dataDisplay/PillComponent';
import Text from '../../../common/dataDisplay/Text';

interface AlterDatePillProps {
  dateTime: Date;
  klassDuration: number | undefined | 90;
  isEditView?: boolean;
  setModalState?: () => void;
  hasDone: boolean;
}

export const AlterDatePill = ({
  dateTime,
  klassDuration,
  isEditView,
  setModalState,
  hasDone,
}: AlterDatePillProps) => {
  const { user } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });

  return (
    <PillComponent testId="alter-date">
      <Text
        text={moment(dateTime).format('DD/MM')}
        size="text-14"
        format="poppins-600"
        className="text-primary h-5 flex self-center"
      />
      <Text
        text={`${moment(dateTime).format('HH:mm')}-${moment(dateTime)
          .add(klassDuration || 90, 'minutes')
          .format('HH:mm')}`}
        size="text-14"
        className="text-base-content h-fit ml-2"
      />
      <Collapse
        orientation="horizontal"
        in={isEditView && !isTeacher(user?.userType) && !hasDone}
        collapsedSize={0}
      >
        <MainButton
          icon={<SwitchHorizontalIcon />}
          color="custom"
          className="text-primary gap-1 ml-2 whitespace-nowrap"
          onClick={setModalState}
          text={t('alterLessonDate')}
        />
      </Collapse>
    </PillComponent>
  );
};
