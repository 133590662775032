import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { UsersIcon } from '@heroicons/react/outline';

import Skeleton from '@/components/common/Skeleton';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { coursePathsQueryKeys } from '@/data/services/querykeys';
import { CourseTypeEnum } from '@/models/Course';
import Klass from '@/models/Klass';
import Text from '@/components/common/dataDisplay/Text';
import Tag from '@/components/common/dataDisplay/Tag';
import { isFinishedKlass, isLastTwoWeeksLesson } from '@/utils/teachingLesson';
import { ROUTES } from '@/utils/routes';
import KlassPopup from '../KlassPopup/KlassPopup';
import NextLessonKlass from '../NextLessonKlass';
import { TeachingWeekdayProps } from './TeachingWeekday';

export type WeekdayKlassProps = Partial<TeachingWeekdayProps> & {
  klass: Klass;
};

export default function WeekdayKlassCell({
  klass,
  onChangeModal,
}: WeekdayKlassProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'weekdayKlassCell',
  });

  const { data: coursePath, isInitialLoading: loading } = useQuery({
    ...coursePathsQueryKeys.get(klass.coursePathSlug),
    enabled: !!klass,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const endDate = moment(klass.klassEndDate).format('DD/MM');

  const lastTwoWeeksLesson = isLastTwoWeeksLesson(klass.klassEndDate);

  const finishedKlass = isFinishedKlass(klass.klassEndDate);

  const to = ROUTES.ADMIN.CLASSES.INFO({
    name: klass.name.replace('#', ''),
  });

  if (loading)
    return (
      <Skeleton
        testId="loading"
        className="p-2.5 min-h-52 max-h-52 xl:min-h-44 xl:max-h-44 w-full rounded-xl bg-primary-content"
      />
    );

  if (coursePath?.course.courseType) {
    const colors: {
      [key in CourseTypeEnum]: string;
    } = {
      CTRL_KIDS: 'bg-warning-content border border-warning',
      CTRL_TEENS: 'bg-primary-content border border-primary',
      CTRL_YOUNG: 'bg-secondary-content border border-secondary',
      CTRL_PRO: 'bg-neutral-content border border-neutral',
      CTRL_QUICK: 'bg-accent-content border border-accent',
    };

    return (
      <li
        data-testid="weekdayKlassCell"
        className={`flex flex-col rounded-xl text-primary p-2.5 gap-1 min-h-40 max-h-40 justify-between overflow-x-hidden w-full ${
          colors[coursePath.course.courseType]
        }`}
      >
        <Text
          format="rubik-700"
          text={klass.name}
          href={to}
          className="text-14 xl:text-16"
        />

        <Tag
          testId="endDate"
          className={`flex justify-center py-1 px-2.5 rounded-xl gap-1 self-center ${
            lastTwoWeeksLesson ? 'bg-info text-base-100' : 'text-base-content'
          }`}
          color="custom"
        >
          <Text
            format="rubik-500"
            text={`${t('termination')}: `}
            className="text-14 xl:text-16"
          />
          <Text
            format="rubik-400"
            text={endDate}
            className="text-14 xl:text-16"
          />
        </Tag>

        <NextLessonKlass
          klassId={finishedKlass ? undefined : klass.id}
          courseSlug={coursePath.slug}
        />

        <section className="flex w-full justify-between gap-1 text-center">
          <div className="flex gap-0.5 items-center">
            <Text format="rubik-700" text={klass.enrollsCount} />
            <UsersIcon className="w-3.5" strokeWidth={3} />
          </div>
          <KlassPopup klass={klass} onChangeModal={onChangeModal} />
        </section>
      </li>
    );
  }

  return null;
}
