import useInfiniteService from '@/data/hook/useInfiniteService';
import { ListAlertsFilters } from '@/data/services/alertServices';
import { alertQueryKeys } from '@/data/services/querykeys';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '../common/ConditionalRenderer';
import Text from '../common/dataDisplay/Text';
import InfinityList from '../common/InfinityList';
import { LoadingIcon } from '../icons';
import NotificationFactory from './cards/factory/NotificationFactory';
import { NotificationFilterFields } from './NotificationTypes';

type ListNotifications = {
  filters: NotificationFilterFields;
};

function adapterFilterFieldsToFiltersService(
  filters: NotificationFilterFields,
): ListAlertsFilters {
  let filtersService: ListAlertsFilters = {};
  if (filters.status === 'today')
    filtersService.alertAt = moment().format('YYYY-MM-DD');
  if (filters.status === 'yesterday')
    filtersService.alertAt = moment()
      .add({
        days: -1,
      })
      .format('YYYY-MM-DD');
  if (filters.status === 'all') {
    delete filtersService.alertAt;
  }

  if (filters.onlyPendings) filtersService.isRead = false;
  else delete filtersService.isRead;

  filtersService.ordering = filters.sortBy === 'asc' ? 'alert_at' : '-alert_at';

  filtersService.type = filters.types.length ? filters.types : undefined;
  return filtersService;
}

export default function NotificationsList({ filters }: ListNotifications) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts.list',
  });
  const filtersAdapter = adapterFilterFieldsToFiltersService(filters);
  const {
    results: alerts,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteService(alertQueryKeys.list(filtersAdapter)._ctx.infinity);

  return (
    <div
      className="w-full pt-2 flex flex-col gap-2.5 overflow-hidden"
      data-testid="notificationsList"
    >
      <ConditionalRenderer condition={isInitialLoading}>
        <div className="flex justify-center w-full text-primary">
          <LoadingIcon className="w-5 h-5" />
        </div>
      </ConditionalRenderer>
      <ConditionalRenderer condition={alerts.length === 0 && !isInitialLoading}>
        <div className="flex justify-center w-full text-primary">
          <Text text={t('empty')} className="text-base-content" />
        </div>
      </ConditionalRenderer>
      <InfinityList
        className="flex flex-col gap-1.5 h-full pr-2"
        onReachEnd={fetchNextPage}
        scroll
        hasNextPage={hasNextPage}
      >
        {alerts.map(notification => (
          <NotificationFactory
            key={notification.id}
            filters={filtersAdapter}
            notification={notification}
          />
        ))}
      </InfinityList>
    </div>
  );
}
