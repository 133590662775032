import { useState } from 'react';

import { ListKlassesFilters } from '@/data/services/klassServices';
import { isTeacher } from '@/functions/auth';
import { KlassStatusEnum } from '@/models/Klass';
import User from '@/models/User';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { UpdateFilter } from '@/models/TeachingWeek';

type UseTeachingWeek = {
  user: User;
};

export function useTeachingWeek({ user }: UseTeachingWeek) {
  const teacherView = isTeacher(user.userType);

  const [filters, setFilters] = useState<ListKlassesFilters>({
    teacherId: teacherView ? user.id : undefined,
    status: [KlassStatusEnum.IN_PROGRESS],
    unitId: getAuthorizedUnits(user),
    ordering: 'klass_start_date',
  });

  const [events, setEvents] = useState<UpdateFilter[]>([]);

  const updateFilter = (filter: ListKlassesFilters) =>
    setFilters(prev => {
      return { ...prev, ...filter };
    });

  function updateFilters({ type: typeUpdated, event }: UpdateFilter) {
    const { value, checked } = event;

    setEvents(prev => {
      const updated = checked
        ? [...prev, { type: typeUpdated, event }]
        : prev.filter(element => element.event.value !== value);

      const eventsByType = updated.filter(({ type }) => type === typeUpdated);

      updateFilter({
        [typeUpdated]: eventsByType.length
          ? eventsByType.map(({ event }) => event.value)
          : undefined,
      });

      return updated;
    });
  }

  return {
    filters,
    events,
    updateFilters,
    updateFilter,
  };
}
