import useCountDown from '@/data/hook/useCountDown';
import useListService from '@/data/hook/useListService';
import { createAlert } from '@/data/services/alertServices';
import { alertQueryKeys } from '@/data/services/querykeys';
import { AlertTypeEnum } from '@/models/Alert';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import ScheduledLessonReport from '@/models/ScheduledLessonReport';
import { getErrorMessage } from '@/utils/getErrorMessage';
import alert from '@/utils/UseAlert';
import { CheckIcon, ClockIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import MainButton from '../common/buttons/MainButton';
import ConditionalRenderer from '../common/ConditionalRenderer';
import { twMerge } from 'tailwind-merge';

export type NotifyLatelessButtonProps = {
  studentId: number;
  klass: Klass;
  scheduledLesson: ScheduledLesson;
  scheduledLessonReport?: ScheduledLessonReport;
  setRef?: (ref: HTMLSpanElement) => void;
};

const NotifyLatelessButton = ({
  studentId,
  scheduledLesson,
  scheduledLessonReport,
  klass,
  setRef,
}: NotifyLatelessButtonProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport',
  });
  const {
    results: alerts,
    isInitialLoading,
    invalidate,
  } = useListService(
    alertQueryKeys.list({
      actorId: studentId,
      targetId: scheduledLesson.id,
      type: [AlertTypeEnum.LATENESS],
    }),
  );
  const startLesson = moment(scheduledLesson.datetime).diff(
    moment(),
    'seconds',
  );
  const endLessonDatetime = moment(scheduledLesson.datetime).add({
    minutes: klass?.duration,
  });

  const endLesson = endLessonDatetime.diff(moment(), 'seconds');
  const { timeLeft: timerStartLesson } = useCountDown(startLesson);
  const { timeLeft: timerEndLesson } = useCountDown(endLesson);
  const notify = async () => {
    await createAlert({
      type: AlertTypeEnum.LATENESS,
      actorId: studentId,
      targetId: scheduledLesson.id,
      alertAt: moment().toISOString(),
    });

    await invalidate();
  };

  const { mutate: onNotify, isLoading } = useMutation(notify, {
    onSuccess: invalidate,
    onError: error => {
      const errorMsg = getErrorMessage(error);
      alert.error(errorMsg);
    },
  });

  const lessonOnGoing = timerStartLesson <= 0 && timerEndLesson > 0;

  const emptyReport = !scheduledLessonReport?.presence;

  const showButton = lessonOnGoing && emptyReport;

  const hasAlert = !!alerts.length;

  return (
    <ConditionalRenderer condition={showButton && !!klass}>
      <span ref={setRef}>
        <MainButton
          text={t(hasAlert ? 'notifySend' : 'notifyLateless')}
          icon={hasAlert ? <CheckIcon /> : <ClockIcon />}
          onClick={() => onNotify()}
          color={hasAlert ? 'custom' : 'primary'}
          className={twMerge(
            'whitespace-nowrap',
            hasAlert ? 'pointer-events-none' : '',
          )}
          loading={isInitialLoading || isLoading}
        />
      </span>
    </ConditionalRenderer>
  );
};

export default NotifyLatelessButton;
