import requester from '@/axios';
import { Alert, AlertDTO, AlertTypeEnum } from '@/models/Alert';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';

const BASE_URL = '/alerts';

export type AlertOrdering = 'alert_at' | '-alert_at';

export type ListAlertsFilters = {
  type?: AlertTypeEnum[];
  targetId?: number;
  actorId?: number;
  isRead?: boolean;
  alertAt?: string;
  ordering?: AlertOrdering;
} & BaseFilters;

export const listAlerts = async (filters: ListAlertsFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<Alert>>(
    `${BASE_URL}/`,
    {
      params: filters,
    },
  );
  return data;
};

export const getAlert = async (id: number) => {
  const { data: alert } = await requester().get<Alert>(`${BASE_URL}/${id}/`);
  return alert;
};

export const updateAlert = async (id: number, updatedData: Partial<Alert>) => {
  const { data: alert } = await requester().patch<Alert>(
    `${BASE_URL}/${id}/`,
    updatedData,
  );
  return alert;
};

export const deleteAlert = async (id: number) => {
  await requester().delete(`${BASE_URL}/${id}/`);
};

export const createAlert = async (alertDto: AlertDTO) => {
  const { data: alert } = await requester().post<Alert>(
    BASE_URL + '/',
    alertDto,
  );
  return alert;
};
