import { BellIcon, RefreshIcon } from '@heroicons/react/outline';
import {
  ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import SimpleTextIcon from '../common/SimpleTextIcon';
import NotificationFilters from './NotificationFilters';
import NotificationsList from './NotificationsList';
import { NotificationFilterFields } from './NotificationTypes';
import IconButton from '../common/buttons/IconButton';
import { alertQueryKeys } from '@/data/services/querykeys';
import { useQueryClient } from '@tanstack/react-query';
import { TIME_REFRESH_NOTIFICATIONS } from '@/constants';

const Notifications = forwardRef<
  HTMLDivElement,
  Pick<ComponentProps<'div'>, 'className'>
>(({ className }, ref) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'alerts',
  });
  const [filters, setFilters] = useState<NotificationFilterFields>({
    sortBy: 'desc',
    status: 'all',
    types: [],
    onlyPendings: false,
  });
  const onFilter = (fields: NotificationFilterFields) => setFilters(fields);
  const queryClient = useQueryClient();

  const refreshNotifications = useCallback(() => {
    const queryKeys = alertQueryKeys.list._def;
    queryClient.invalidateQueries(queryKeys);
  }, [queryClient]);

  useEffect(() => {
    const intervalId = setInterval(
      refreshNotifications,
      TIME_REFRESH_NOTIFICATIONS,
    );
    return () => clearInterval(intervalId);
  }, [refreshNotifications]);

  return (
    <div
      className={twMerge(
        'relative flex flex-col gap-2.5 border border-neutral-content rounded-xl py-2.5 px-4 lg:min-w-[400px] xl:w-[680px]',
        className,
      )}
      ref={ref}
    >
      <div className="flex justify-between">
        <SimpleTextIcon
          icon={<BellIcon className="h-6 w-6" />}
          title={t('title')}
          className={{ text: 'text-accent' }}
        />
        <IconButton
          icon={<RefreshIcon className="w-6 text-accent" />}
          onClick={refreshNotifications}
        />
      </div>
      <NotificationFilters onFilter={onFilter} filters={filters} />
      <NotificationsList filters={filters} />
    </div>
  );
});

export default Notifications;
