import useToggle from '@/data/hook/useToggle';
import { accordion } from '@/utils/animations/commom';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import {
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import Badge from '../dataDisplay/Badge';
import ConditionalWrapper from '../ConditionalWrapper';
import Checkbox from './Checkbox';

export type CheckOption = {
  value: string | number;
  label: ReactNode | string;
} & InputHTMLAttributes<HTMLInputElement>;

type CheckGroupProps = {
  options: CheckOption[];
} & InputHTMLAttributes<HTMLInputElement>;

const CheckGroup = forwardRef<HTMLInputElement, CheckGroupProps>(
  (props, ref) => {
    const { options, ...input } = props;
    return (
      <ul className="pr-1">
        {options.map(option => {
          const checkboxId = 'checkbox' + option.value;
          return (
            <li key={option.value} className="mt-1.5">
              <Checkbox
                {...input}
                {...option}
                id={checkboxId}
                ref={ref}
                className={{ input: input.className }}
              />
            </li>
          );
        })}
      </ul>
    );
  },
);

type CheckGroupContainerProps = {
  title: string;
  testId?: string;
  badgeCount?: number;
} & HTMLAttributes<HTMLDivElement>;

const CheckGroupContainer = ({
  children,
  title,
  badgeCount,
  testId,
}: CheckGroupContainerProps) => {
  const { isOpen, toggle } = useToggle(true);

  return (
    <div className="relative">
      <button
        onClick={toggle}
        data-testid={testId}
        className="flex gap-1.5 justify-start items-center"
      >
        <ConditionalWrapper
          condition={!!badgeCount}
          wrapper={
            <Badge
              position="-top-1 -right-2"
              color={'info'}
              count={badgeCount}
            />
          }
        >
          <span className="text-base-content">{title}</span>
        </ConditionalWrapper>

        <ChevronDownIcon className="w-5 h-5" />
      </button>
      <div className="relative overflow-hidden">
        <AnimatePresence>
          {isOpen && <motion.div {...accordion}>{children}</motion.div>}
        </AnimatePresence>
      </div>
    </div>
  );
};

export { CheckGroupContainer };
export default CheckGroup;
