import { ChevronRightIcon, LogoutIcon } from '@heroicons/react/outline';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from '@/data/hook/useAuth';
import useSidebar from '@/data/hook/useSidebar';
import { pathChecker } from '@/utils/pathChecker';
import ComponentGuard from '../../common/ComponentGuard';
import SidebarItem from '../SidebarItem';
import useOptions, { Option } from './Options';

export default function SidebarDesktop() {
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'sideMenu',
  });

  useEffect(() => {
    if (user === null) {
      navigate('/login');
    }
  }, [user, navigate]);

  const options = useOptions();

  const { isOpen, UpdateSidebarState } = useSidebar();

  const isIncludedPath = useRef(pathChecker());

  useEffect(() => {
    if (isIncludedPath.current) UpdateSidebarState!(false);
    return;
  }, [UpdateSidebarState]);

  function handleOptionsMap(
    { renderCondition, ...option }: Option,
    index: number,
    options: Option[],
  ) {
    const handleRenderCondition = renderCondition.hasCondition
      ? renderCondition.condition
      : undefined;
    if (option.roles === 'ALL') {
      return (
        <SidebarItem
          testId={option.testId}
          key={index}
          icon={option.icon}
          isMenuOpen={isOpen}
          text={option.title}
          url={option.url}
          renderCondition={handleRenderCondition}
        />
      );
    } else {
      return (
        <ComponentGuard key={index} roles={option.roles}>
          <SidebarItem
            testId={option.testId}
            icon={option.icon}
            isMenuOpen={isOpen}
            text={option.title}
            url={option.url}
            renderCondition={handleRenderCondition}
          />
        </ComponentGuard>
      );
    }
  }

  return (
    <div
      className={`hidden relative h-screen lg:block transition-all duration-400 shrink-0 z-10 ${
        isOpen ? 'lg:w-[13.25rem]' : 'lg:w-[3.75rem]'
      }`}
    >
      <div
        data-testid="sidebarCollapseButton"
        className={`hidden transition-all duration-400 lg:block fixed top-14 cursor-pointer -z-10 bg-secondary text-base-100 pl-3 pr-1 py-2 rounded-full ${
          isOpen ? 'left-[12.25rem]' : 'left-[2.75rem]'
        }`}
        onClick={() => {
          UpdateSidebarState!(!isOpen);
        }}
      >
        <ChevronRightIcon
          className={`w-4 h-4 duration-300 transition-all ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </div>
      <aside
        data-testid="sidebarComponent"
        id="sidebar"
        className={`lg:left-0 h-screen bg-gradient-to-t from-primary transition-all duration-400 to-secondary flex z-5 fixed flex-col justify-between lg:px-3.5 py-[5vh] ${
          isOpen ? 'lg:w-[13.25rem]' : 'lg:w-[3.75rem]'
        }`}
      >
        <div className="flex flex-col gap-[7vh]">
          <div className="lg:justify-center flex flex-row-reverse items-center w-full justify-between self-center">
            {!isOpen ? (
              <img
                className="w-8 h-8 self-center"
                src="/images/CtrlPlayShortLogoWhite.png"
                alt="CP"
              />
            ) : (
              <img
                className="w-full max-w-[144px] self-center"
                src="/images/CtrlPlayFullLogoWhite.png"
                alt="Ctrl+Play"
              />
            )}
          </div>
          <div className="flex flex-col gap-[3vh] ">
            {options.map(handleOptionsMap)}
          </div>
        </div>
        <SidebarItem
          url="/login"
          isMenuOpen={isOpen}
          onClick={logout}
          icon={<LogoutIcon />}
          testId="sidebarLogout"
          text={t('leave')}
        />
      </aside>
    </div>
  );
}
