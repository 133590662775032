import { useTranslation } from 'react-i18next';
import { FilterType } from '@/models/FilterType';

export default function useTranslateFilter() {
  const { t: tKlassStatus } = useTranslation('translation', {
    keyPrefix: 'klassStatus',
  });

  const { t: tUserStatus } = useTranslation('translation', {
    keyPrefix: 'userStatus',
  });

  const { t: tEnrollmentStatus } = useTranslation('translation', {
    keyPrefix: 'enrollmentStatus',
  });

  const { t: tUserTypes } = useTranslation('translation', {
    keyPrefix: 'userTypes',
  });

  const { t: tCourseTypes } = useTranslation('translation', {
    keyPrefix: 'courseTypes',
  });

  const { t: tCourseCategories } = useTranslation('translation', {
    keyPrefix: 'courseCategory',
  });

  const { t: tDaysOfWeek } = useTranslation('translation', {
    keyPrefix: 'weekdays',
  });

  type Filter = Exclude<FilterType, 'unit' | 'unitId'>;

  const translate: {
    [key in Filter]: (text: string) => string;
  } = {
    klassStatus: tKlassStatus,
    enrollmentStatus: tEnrollmentStatus,
    userType: tUserTypes,
    courseType: tCourseTypes,
    userStatus: tUserStatus,
    staffStatus: tUserStatus,
    courseCategory: tCourseCategories,
    dayOfWeek: tDaysOfWeek,
  };

  const translateItem = ({
    type,
    item,
  }: {
    type: FilterType;
    item: string;
  }) => {
    switch (type) {
      case 'enrollmentStatus':
      case 'klassStatus':
      case 'userStatus':
      case 'userType':
      case 'courseType':
      case 'staffStatus':
      case 'dayOfWeek':
      case 'courseCategory':
        return translate[type](item);
      default:
        return '';
    }
  };

  return { translateItem };
}
